import React from "react";
import SectionTitle from "../SectionTitle";
import Section from "../Section";

const Livestream = () => (
  <div>
    <Section background>
      <SectionTitle
        title="Livestream 29th November - Day 1"
        desc=""
      />
      <div>
        <style>
          {
            ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
          }
        </style>

        <div class="embed-container">
          <iframe
            title="Livestream 29th November - Day 1"
            src="https://www.youtube.com/embed/NkdlxjeuhWI"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </Section>
    <Section background>
      <SectionTitle
        title="Livestream 30th November - Day 2"
        desc=""
      />
      <div>
        <style>
          {
            ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
          }
        </style>

        <div class="embed-container">
          <iframe
            title="Livestream 30th November - Day 2"
            src="https://www.youtube.com/embed/akoHb7rLNcE"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </Section>
  </div>
);

export default Livestream;
